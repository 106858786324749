/* EVENT IMAGES */
import GaneshJayanti from "../assets/images/events/ganesh-jayanti.jpg";
import VasantPanchmi from "../assets/images/events/vasant-panchmi.jpg";
import MahaShivratri from "../assets/images/events/maha-shivratri.jpg";
import PhalgunPurnima from "../assets/images/events/phalgun-purnima.jpg";
import HoliFestival from "../assets/images/events/holi-festival.jpg";
import Navratri from "../assets/images/events/chaitra-navratri.jpg";

const events = [
  {
    id: 1,
    image: GaneshJayanti,
    date: "Tuesday, Feb 13, 2024",
    name: "Ganesh Jayanti",
    description:
      "Ganesh Jayanti is observed as the birth anniversary of Lord Ganesha. As per Hindu calendar and spirituality, it is observed on Shukla Chaturthi during Magha Lunar month.",
  },
  {
    id: 2,
    image: VasantPanchmi,
    date: "Wednesday, Feb 14, 2024",
    name: "Vasant Panchami",
    description:
      "Vasant Panchami always fall on the fifth day of Magha Maas. Goddess Saraswati is known to have been incarnated on the day of Basant Panchmi.",
  },
  {
    id: 3,
    image: MahaShivratri,
    date: "Friday, Mar 08, 2024",
    name: "Maha Shivratri",
    description:
      "Maha Shivratri, celebrated in reverence of Lord Shiva, is a Hindu festival marked by fasting, night vigils, and prayers, symbolizing spiritual awakening and divine union.",
  },
  {
    id: 4,
    image: PhalgunPurnima,
    date: "Sunday, Mar 24, 2024",
    name: "Phalguna Purnima",
    description:
      "Phalgun Purnima, a Hindu festival, marks the full moon day in the month of Phalguna. Celebrated with colorful rituals, it signifies the arrival of spring and spiritual renewal.",
  },
  {
    id: 5,
    image: HoliFestival,
    date: "Monday, Mar 25, 2024",
    name: "Holi Festival",
    description:
      "Holi is a popular ancient Indian festival, also known as the 'Festival of Love', the 'Festival of Colours' and the 'Festival of Spring'. This festival celebrates divine love of Krishna.",
  },
  {
    id: 6,
    image: Navratri,
    date: "Tuesday, Apr 09, 2024",
    name: "Chaitra Navratri",
    description:
      "Chaitra Navratri is a Hindu festival spanning nine nights in the month of Chaitra. Devotees worship Goddess Durga, seeking her blessings for spiritual growth.",
  },
];

export default events;
