import request from "./request";

// Contact Us API
export const addContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/contactUs", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Landing Page Form API
export const addLandingPageForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/landingPage", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};