/* ASSETS */
import GrihaPravesh from "../assets/images/pujas/griha-pravesh-pooja.jpg";
import SatyaNarayanPuja from "../assets/images/pujas/satyanarayan-puja.jpg";
import GanpatiPuja from "../assets/images/pujas/ganpati-puja.jpg";
import SaraswatiPuja from "../assets/images/pujas/saraswati-puja.jpg";

const pujas = [
  {
    id: 1,
    image: GrihaPravesh,
    name: "Griha Pravesh Puja",
    desc: "Griha Pravesh is a Hindu housewarming ceremony, where rituals and prayers are performed to invoke blessings, harmony, and prosperity before occupying a new home.",
  },
  {
    id: 2,
    image: SatyaNarayanPuja,
    name: "Satyanarayan Puja",
    desc: "Satyanarayan Puja is a Hindu ritual dedicated to Lord Vishnu, performed to seek blessings for prosperity and well-being. It involves prayers, storytelling, and distribution of prasad.",
  },
  {
    id: 3,
    image: GanpatiPuja,
    name: "Maha Ganpati Homa",
    desc: "Maha Ganpati Homa is a sacred Hindu ritual dedicated to Lord Ganesha, involving fire offerings and chanting of mantras to seek blessings for wisdom, success, and obstacle removal.",
  },
  {
    id: 4,
    image: SaraswatiPuja,
    name: "Saraswati Puja",
    desc: "Saraswati Puja is a Hindu festival venerating Goddess Saraswati, the deity of knowledge and arts. Devotees perform rituals and seek blessings for wisdom and academic success.",
  },
];

export default pujas;
