import { useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { PopupModal } from "react-calendly";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Collapse } from "@material-tailwind/react";

/* ICONS */
import { IoLogoInstagram, IoMail, IoLogoLinkedin } from "react-icons/io5";
import { Bars2Icon } from "@heroicons/react/24/outline";

/* DATA */
import events from "../data/events";
import pujas from "../data/pujas";

/* API */
import { addContactUs } from "../service/api";

/* ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

/* VALIDATION SCHEMA */
const formValidation = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .required("Email address is required")
    .email("Email address must be valid"),
  phone: yup.string().required("Phone is required"),
  message: yup
    .string()
    .required(
      "Please enter a message. Your message should be at least 10 characters long"
    ),
});

const Homepage = () => {
  const [menu, setMenu] = useState(false);
  const [calendlyModal, setCalendlyModal] = useState(false);

  /* TESTIMONIAL BOX */
  const TestimonialBox = (props) => {
    return (
      <div className="w-full bg-white border border-greyBorder rounded-lg shadow p-5 first:mt-0 mt-5">
        <div>
          <p className="text-base font-proxima-semibold text-black">
            {props.name}
          </p>
          <p className="text-sm font-proxima-regular text-gray-500">
            {props.conducted}
          </p>
        </div>
        <div className="mt-3">
          <p className="text-base font-proxima-regular text-black">
            {props.desc}
          </p>
        </div>
      </div>
    );
  };

  /* EVENT BOX */
  const EventBox = (props) => {
    return (
      <div className="bg-white border border-[#E5E5E5] rounded-lg">
        <img
          src={props.image}
          className="w-full h-[20vh] object-cover rounded-md rounded-b-none"
          alt="Ganesh Jayanti"
        />
        <div className="p-5">
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {props.date}
          </p>
          <p className="text-black font-proxima-bold text-lg mt-2">
            {props.name}
          </p>
          <p className="mt-1 font-proxima-regular text-black text-sm">
            {props.description}
          </p>
        </div>
      </div>
    );
  };

  /* PUJA BOX */
  const PujaBox = (props) => {
    return (
      <div className="bg-white rounded-lg shadow-md border border-[#E5E5E5]">
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
          <div className="col-span-1">
            <img
              src={props.image}
              className="w-full hh-[25vh] xl:h-[20.5vh] lg:h-[20.5vh] object-cover rounded-t-lg xl:rounded-l-lg lg:rounded-l-lg"
              alt="Griha Pravesh"
            />
          </div>
          <div className="col-span-2 p-5">
            <h5 className="text-xl font-proxima-bold text-black">
              {props.name}
            </h5>
            <p className="text-[1.6vh] font-proxima-regular text-gray-600 mt-1">
              {props.description}
            </p>
            <Button
              className="text-sm font-proxima-semibold font-normal bg-black mt-4 shadow-none hover:shadow-none normal-case px-5 py-2 rounded-md"
              onClick={() => setCalendlyModal(!calendlyModal)}
            >
              Book the puja now
            </Button>

            <PopupModal
              url="https://calendly.com/book-puja/45min"
              open={calendlyModal}
              onModalClose={() => setCalendlyModal(!calendlyModal)}
              rootElement={document.getElementById("root")}
            />
          </div>
        </div>
      </div>
    );
  };

  /* FORM HANDLER */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      addContactUs(values)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Thank you for contacting me. I will get back to you as soon as possible."
            );
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(err?.message);
          }
        });
      resetForm();
    },
  });

  return (
    <>
      <ToastContainer />
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="bg-white"
      >
        {/* HEADER */}
        <header className="bg-white main-header py-2 w-full z-50">
          <div className="container mx-auto">
            <div className="flex items-center justify-between">
              <img
                src={require("../assets/images/logo.png")}
                className="w-16 h-auto"
                alt="Anjali - The Spiritual Dance Guru"
              />

              <div className="items-center gap-8 hidden xl:flex lg:flex">
                <a
                  href="#about-anjali-kp"
                  className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                >
                  About Anjali
                </a>
                <a
                  href="#featured-pujas"
                  className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                >
                  Featured Pujas
                </a>
                <a
                  href="#upcoming-events"
                  className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                >
                  Upcoming Events
                </a>
                <a
                  href="#gallery"
                  className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                >
                  Gallery
                </a>
                <a
                  href="#customer-reviews"
                  className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                >
                  Customer Reviews
                </a>
              </div>

              {/* MENU */}
              <Button
                className="bg-transparent shadow-none hover:shadow-none border border-[#E5E5E5] p-2 rounded-full hover:bg-black hover:bg-opacity-10 block xl:hidden lg:hidden"
                onClick={() => setMenu(!menu)}
              >
                <Bars2Icon className="w-4 h-4 text-black" />
              </Button>
            </div>
          </div>

          {/* COLLAPSE */}
          <Collapse open={menu} placement="bottom" className="!opacity-100">
            <div className="p-5 bg-white border-b border-[#E5E5E5]">
              <a
                href="#about-anjali-kp"
                className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out"
                onClick={() => setMenu(!menu)}
              >
                About Anjali
              </a>
              <a
                href="#featured-pujas"
                className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out mt-2"
                onClick={() => setMenu(!menu)}
              >
                Featured Pujas
              </a>
              <a
                href="#upcoming-events"
                className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out mt-2"
                onClick={() => setMenu(!menu)}
              >
                Upcoming Events
              </a>
              <a
                href="#gallery"
                className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out mt-2"
                onClick={() => setMenu(!menu)}
              >
                Gallery
              </a>
              <a
                href="#customer-reviews"
                className="block text-base font-proxima-semibold text-black hover:text-logoColor transition-all duration-300 ease-in-out mt-2"
                onClick={() => setMenu(!menu)}
              >
                Customer Reviews
              </a>
            </div>
          </Collapse>
        </header>

        {/* BANNER */}
        <motion.div variants={animation} className="banner">
          <div className="bg-black bg-opacity-70 py-16 xl:py-[12vh] lg:py-[12vh">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-10 xl:gap-20 lg:gap-20 items-center">
                <div className="col-span-1 order-2 xl:order-1 lg:order-1">
                  <h1 className="text-white font-proxima-extrabold text-5xl leading-tight">
                    BEST PUJA EXPERIENCE WITH ANJALI KP
                  </h1>
                  <h2 className="text-white text-opacity-80 font-proxima-regular tracking-tight mt-8 text-2xl">
                    Anjali KP, a spiritual guide, seamlessly blends yajnas with
                    IT prowess and mesmerizing dance, embodying a harmonious
                    fusion of disciplines.
                  </h2>
                  <div className="flex items-center gap-3 mt-8">
                    <p className="text-xl font-proxima-regular text-white text-opacity-80">
                      Follow me on
                    </p>
                    <a
                      href="https://www.instagram.com/aryasamajyagya/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="flex items-center gap-2 shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-15 border border-white rounded-full px-4 py-2 text-lg font-proxima-regular normal-case font-normal">
                        <IoLogoInstagram className="w-5 h-5 text-white" />
                        Instagram
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-end order-1 xl:order-2 lg:order-2">
                  <div className="w-full xl:w-4/5 lg:w-4/5 bg-white rounded-xl shadow-xl p-8">
                    <h5 className="text-2xl font-proxima-bold text-black">
                      Connect with me today!
                    </h5>
                    <form className="mt-3" onSubmit={formik.handleSubmit}>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              First Name <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              id="firstName"
                              className={`block w-full border ${
                                formik.errors.firstName
                                  ? "border-red-500"
                                  : "border-[#E5E5E5]"
                              } rounded-md text-sm font-proxima-semibold text-black outline-none focus:outline-none mt-1 px-5 py-2 placeholder:font-proxima-regular`}
                              placeholder="First Name"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Last Name <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              id="lastName"
                              className={`block w-full border ${
                                formik.errors.firstName
                                  ? "border-red-500"
                                  : "border-[#E5E5E5]"
                              } rounded-md text-sm font-proxima-semibold text-black outline-none focus:outline-none mt-1 px-5 py-2 placeholder:font-proxima-regular`}
                              placeholder="Last Name"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-5">
                        <label className="text-sm font-proxima-semibold text-black">
                          Email address <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className={`block w-full border ${
                            formik.errors.firstName
                              ? "border-red-500"
                              : "border-[#E5E5E5]"
                          } rounded-md text-sm font-proxima-semibold text-black outline-none focus:outline-none mt-1 px-5 py-2 placeholder:font-proxima-regular`}
                          placeholder="Email address"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="form-group mt-5">
                        <label className="text-sm font-proxima-semibold text-black">
                          Phone Number <span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                          defaultCountry="US"
                          placeholder="Phone"
                          countries={["US", "IN"]}
                          value={formik.values.phone}
                          onChange={(e) => formik.setFieldValue("phone", e)}
                          className={`${
                            formik.errors.phone
                              ? "border border-red-500"
                              : "border border-[#E5E5E5]"
                          } rounded-md mt-1 phone`}
                        />
                      </div>
                      <div className="form-group mt-5">
                        <label className="text-sm font-proxima-semibold text-black">
                          Message <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          rows="4"
                          name="message"
                          id="message"
                          className={`block w-full border ${
                            formik.errors.firstName
                              ? "border-red-500"
                              : "border-[#E5E5E5]"
                          } rounded-md text-sm font-proxima-semibold text-black outline-none focus:outline-none mt-1 px-5 py-2 placeholder:font-proxima-regular`}
                          placeholder="Short message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <Button
                        type="submit"
                        className="text-sm font-proxima-semibold text-white bg-black hover:bg-opacity-80 mt-5 shadow-none hover:shadow-none px-8 py-3 normal-case"
                      >
                        Submit details
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* ABOUT ANJALI KP */}
        <motion.div
          variants={animation}
          className="py-6 xl:py-12 lg:py-12"
          id="about-anjali-kp"
        >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-10">
              <div className="col-span-1">
                <h2 className="text-4xl font-proxima-extrabold text-black">
                  ABOUT ANJALI KP
                </h2>
                <div className="mt-2 xl:mt-5 lg:mt-5">
                  <p className="text-lg font-proxima-semibold text-black text-opacity-80 tracking-tight">
                    Anjali KP is a multifaceted individual seamlessly blending
                    spirituality, dance, and IT expertise. A devout
                    spiritualist, she conducts yajnas to deepen her connection
                    with the divine. As a seasoned professional dancer, Anjali
                    captivates audiences with her expressive performances,
                    showcasing a harmonious fusion of tradition and innovation.
                  </p>
                  <p className="text-lg font-proxima-semibold text-black text-opacity-80 tracking-tight mt-5">
                    Simultaneously, she excels in the IT realm, leveraging her
                    technological acumen to navigate the digital landscape.
                    Anjali embodies a unique synthesis of spiritual devotion,
                    artistic finesse, and professional acuity, embodying a life
                    dedicated to balance, enlightenment, and the pursuit of
                    excellence in both the spiritual and secular domains.
                  </p>
                  <div className="block xl:flex lg:flex items-center gap-3 mt-5">
                    <p className="text-lg font-proxima-semibold text-black text-opacity-80 tracking-tight">
                      Reach out to me on:{" "}
                    </p>
                    <div className="flex items-center gap-3 mt-2 xl:mt-0 lg:mt-0">
                      <a
                        href="https://www.instagram.com/angelie_009/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="flex items-center gap-2 shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-15 border-2 border-black rounded-full px-4 py-2 text-sm font-proxima-semibold normal-case font-normal text-black">
                          <IoLogoInstagram className="w-5 h-5 text-black" />
                          Instagram
                        </Button>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/anjaleekp/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="flex items-center gap-2 shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-15 border-2 border-black rounded-full px-4 py-2 text-sm font-proxima-semibold normal-case font-normal text-black">
                          <IoLogoLinkedin className="w-5 h-5 text-black" />
                          LinkedIn
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <img
                  src={require("../assets/images/about-anjali.jpg")}
                  className="w-full xl:w-4/5 lg:w-4/5 h-[40vh] object-cover rounded-lg"
                  alt="About Anjali KP"
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* FEATURED PUJAS */}
        <motion.div
          variants={animation}
          className="py-6 xl:py-12 lg:py-12 bg-logoColorLight"
          id="featured-pujas"
        >
          <div className="container mx-auto">
            <h2 className="text-4xl font-proxima-extrabold text-black">
              FEATURED PUJAS
            </h2>
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-10 mt-5">
              {pujas.map((puja) => (
                <div className="col-span-1" key={puja.id}>
                  <PujaBox
                    image={puja.image}
                    name={puja.name}
                    description={puja.desc}
                  />
                </div>
              ))}
            </div>
          </div>
        </motion.div>

        {/* UPCOMINNG EVENTS */}
        <motion.div
          variants={animation}
          className="py-6 xl:py-12 lg:py-12"
          id="upcoming-events"
        >
          <div className="container mx-auto">
            <h2 className="text-4xl font-proxima-extrabold text-black">
              UPCOMING EVENTS
            </h2>
            <div className="mt-5">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                slidesPerView={4}
                spaceBetween={20}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                }}
                autoplay
                className="events-slider"
              >
                {events.map((event) => (
                  <SwiperSlide key={event.id}>
                    <EventBox
                      date={event.date}
                      image={event.image}
                      name={event.name}
                      description={event.description}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </motion.div>

        {/* GALLERY */}
        <motion.div
          variants={animation}
          className="py-6 xl:py-12 lg:py-12 bg-logoColorLight"
          id="gallery"
        >
          <div className="container mx-auto">
            <h2 className="text-4xl font-proxima-extrabold text-black">
              VISIONS UNVEILED - EXPLORE THE GALLERY
            </h2>
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 mt-5">
              <div className="col-span-1">
                <img
                  src={require("../assets/images/gallery/gallery-square-01.webp")}
                  className="w-full h-[25vh] object-cover rounded-lg"
                  alt="Gallery"
                />
                <div className="mt-5">
                  <img
                    src={require("../assets/images/gallery/gallery-long-02.webp")}
                    className="w-full h-[35vh] object-cover rounded-lg"
                    alt="Gallery"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <img
                  src={require("../assets/images/gallery/gallery-square-03.webp")}
                  className="w-full h-[25vh] object-cover rounded-lg"
                  alt="Gallery"
                />
                <div className="mt-5">
                  <img
                    src={require("../assets/images/gallery/gallery-rectangle-01.webp")}
                    className="w-full h-[35vh] object-cover rounded-lg"
                    alt="Gallery"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <img
                  src={require("../assets/images/gallery/gallery-square-04.webp")}
                  className="w-full h-[25vh] object-cover rounded-lg"
                  alt="Gallery"
                />
                <div className="mt-5">
                  <img
                    src={require("../assets/images/gallery/gallery-rectangle-02.webp")}
                    className="w-full h-[35vh] object-cover rounded-lg"
                    alt="Gallery"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-lg w-full h-[50vh] xl:h-[62vh] lg:h-[62vh] object-cover"
                >
                  <source
                    src={require("../assets/images/gallery/gallery-video-01.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </motion.div>

        {/* TESTIMONIALS */}
        <motion.div
          variants={animation}
          className="py-6 xl:py-12 lg:py-12"
          id="customer-reviews"
        >
          <div className="container mx-auto">
            <h2 className="text-4xl font-proxima-extrabold text-black">
              CUSTOMER REVIEWS
            </h2>
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <TestimonialBox
                  name="Vimal Patel"
                  conducted="Griha Pravesh Puja"
                  desc="The Pooja went smoothly with no stress of having to worry about the various stuff required for pooja. Anjali was very good and she was in no rush."
                />
              </div>
              <div className="col-span-1">
                <TestimonialBox
                  name="Priya Sharma"
                  conducted="Satyanarayan Puja"
                  desc="Anjali brought such positive vibes to our home with her yajnas! It felt like a spiritual reset button. We're grateful for the peace she brought."
                />
              </div>
              <div className="col-span-1">
                <TestimonialBox
                  name="Dr Vivek Kakkad"
                  conducted="Maha Ganpati Homa"
                  desc="Anjali's yajnas were like a breath of fresh air. Our home feels lighter, and we've never experienced such tranquility. Highly recommend her spiritual services!"
                />
              </div>
              <div className="col-span-1">
                <TestimonialBox
                  name="Aarav Desai"
                  conducted="Sarawati Puja"
                  desc="Anjali's yajnas created a serene atmosphere. Our family found a new sense of calm. Her rituals were beautiful, and we're eager for her next visit!"
                />
              </div>
              <div className="col-span-1">
                <TestimonialBox
                  name="Sarthak Shah"
                  conducted="Maha Ganpati Homa"
                  desc="Anjali's yajnas were a revelation. Our home transformed into a haven of positivity. Her spiritual energy is contagious, and we're grateful for the divine experience."
                />
              </div>
              <div className="col-span-1">
                <TestimonialBox
                  name="Rajnish Tiwari"
                  conducted="Satyanarayan Puja"
                  desc="Anjali's yajnas added a unique touch to our daily life. Our family feels more connected and blessed. Her spiritual guidance truly made a difference in our home."
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GRADIENT LINE */}
        <div className="w-full py-[0.2vh] bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100" />

        {/* FOOTER */}
        <footer>
          {/* MAIN SECTION */}
          <div className="py-5">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 items-center">
                <div className="col-span-1">
                  <img
                    src={require("../assets/images/logo.png")}
                    className="w-24 h-auto"
                    alt="Anjali - The Spiritual Dance Guru"
                  />
                </div>
                <div className="col-span-2 mt-5 xl:mt-0 lg:mt-0">
                  <div className="flex items-center gap-3 group cursor-pointer">
                    <div className="w-12 h-12 bg-logoColor rounded-full shrink-0 flex items-center justify-center">
                      <IoMail className="w-5 h-5 text-white transition-all duration-300 ease-in-out" />
                    </div>
                    <div>
                      <h5 className="text-sm font-proxima-regular text-black text-opacity-80">
                        Send me an email
                      </h5>
                      <a
                        href="mailto:contact@anjalithespiritualdanceguru.com"
                        className="block text-base font-proxima-semibold text-black group-hover:text-logoColor transition-all duration-300 ease-in-out"
                      >
                        contact@anjalithespiritualdanceguru.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 mt-5 xl:mt-0 lg:mt-0">
                  <h5 className="text-base font-proxima-semibold text-black">
                    Scientifically crafted proven methods to bring positive
                    energies, peace and harmony in life.
                  </h5>
                  <div className="mt-3 flex items-center gap-2">
                    <Button
                      className="shadow-none hover:shadow-none bg-logoColor hover:bg-opacity-80 text-sm font-proxima-semibold text-white rounded-full px-4 py-2 normal-case border-2 border-logoColor"
                      onClick={() => setCalendlyModal(!calendlyModal)}
                    >
                      Book Yagya
                    </Button>
                    <PopupModal
                      url="https://calendly.com/book-puja/45min"
                      open={calendlyModal}
                      onModalClose={() => setCalendlyModal(!calendlyModal)}
                      rootElement={document.getElementById("root")}
                    />
                    <a
                      href="https://www.instagram.com/aryasamajyagya/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="flex items-center gap-2 shadow-none hover:shadow-none bg-transparent hover:bg-logoColor hover:bg-opacity-15 border-2 border-logoColor rounded-full px-4 py-2 text-sm font-proxima-regular normal-case text-logoColor">
                        Watch Yagya
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* COPYRIGHT SECTION */}
          <div className="bg-logoColor py-2">
            <div className="container mx-auto">
              <div className="block xl:flex lg:flex items-center justify-between">
                <p className="text-sm font-proxima-regular text-white text-center">
                  © 2024 Arya Samaj Yagya. All Rights Reserved.
                </p>

                {/* SOCIAL MEDIA LINKS */}
                <div className="flex items-center justify-center gap-3 mt-2 xl:mt-0 lg:mt-0">
                  <p className="text-sm font-proxima-regular text-white">
                    Follow me on
                  </p>
                  <a
                    href="https://www.instagram.com/aryasamajyagya/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="flex items-center gap-2 shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-15 border border-white rounded-full px-4 py-2 text-sm font-proxima-regular normal-case font-normal">
                      <IoLogoInstagram className="w-4 h-4 text-white" />
                      Instagram
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </motion.section>
    </>
  );
};

export default Homepage;
